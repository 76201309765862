import axios from 'src/lib/axios'

const getDefaultState = () => ({
  login: undefined,
  tariff: undefined,
  tariffOptions: [],
  cycleDaysLeft: undefined,
  comfortPayment: undefined,
  autoTopup: undefined,
  activeTariff: undefined
})

const state = getDefaultState()

const getters = {
  hasCustomerData: state => {
    return !!state.login
  },
  getCustomerData: state => {
    return {
      login: state.login,
      tariff: state.tariff,
      tariff_options: state.tariffOptions,
      cycle_days_left: state.cycleDaysLeft,
      comfort_payment: state.comfortPayment,
      auto_topup: state.autoTopup
    }
  },
  getActiveTariff: state => {
    return state.activeTariff
  }
}

const actions = {
  async fetchCustomerData({ rootGetters, commit }) {
    try {
      const response = await axios.get('/rest-api/v1/customer/user-data', {
        headers: {
          ...rootGetters['authentication/getHeader']
        }
      })

      commit('updateCustomerData', response.data)
    } catch (e) {
      // do nothing, tracking errors should not be visible to the customer
    }
  },
  async fetchActiveTariff({ rootGetters, commit, dispatch }) {
    try {
      const response = await axios.get('/rest-api/v1/customer/active-tariff', {
        headers: {
          ...rootGetters['authentication/getHeader']
        }
      })

      commit('updateActiveTariff', response.data)
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
    }
  }
}

const mutations = {
  updateCustomerData(state, data) {
    state.login = data.login
    state.tariff = data.tariff
    state.tariffOptions = data.tariffOptions || []
    state.cycleDaysLeft = data.cycleDaysLeft
    state.comfortPayment = data.comfortPayment
    state.autoTopup = data.autoTopup
  },
  updateActiveTariff(state, data) {
    state.activeTariff = data.activeTariff
  },
  reset(state) {
    Object.assign(state, {
      ...getDefaultState()
    })
  }
}

export default {
  name: 'customer',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
