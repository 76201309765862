const getDefaultState = () => ({
  modalOpen: false,
  modalQueue: []
})

const state = getDefaultState()

const getters = {
  isModalOpen: state => {
    return state.modalOpen
  }
}

const actions = {}

const mutations = {
  pushModal(state, modal) {
    state.modalQueue.push(modal)
    if (state.modalQueue.length === 1) {
      state.modalQueue[0].open()
    }
  },
  removeModal(state, modal) {
    if (state.modalQueue.length === 0) {
      return
    }

    const isFirst = state.modalQueue[0].id === modal.id

    state.modalQueue = state.modalQueue.filter(el => el.id !== modal.id)
    if (isFirst && state.modalQueue.length > 0) {
      state.modalQueue[0].open()
    }
  },
  reset(state) {
    // On reload store is loaded from localStorage.
    // Functions are not stores in localStorage.
    // The modal store need to be resetted.
    if (state.modalQueue[0]?.close) {
      state.modalQueue[0]?.close()
    }

    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'modal',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
