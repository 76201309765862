import { DIRTY, INVISIBLE, OVERVIEW, PRISTINE } from './modes'
import { tracking } from 'src/plugins/tracking'

export const CHECKOUT_PERSONAL_DATA = 0,
  CHECKOUT_ADDRESS_DATA = 1,
  CHECKOUT_BANK_DATA = 2,
  CHECKOUT_TOPUP_DATA = 3,
  CHECKOUT_PASSWORD_DATA = 4,
  CHECKOUT_PERMISSIONS = 5,
  CHECKOUT_VZDOC = 6,
  CHECKOUT_MNP = 7,
  CHECKOUT_MGM = 8

export const getDefaultCheckoutSteps = () => {
  const steps = []

  steps[CHECKOUT_PERSONAL_DATA] = {
    title: 'Vertragsinhaber',
    mode: PRISTINE,
    trackingStepName: 'owner'
  }
  steps[CHECKOUT_ADDRESS_DATA] = {
    title: 'Lieferadresse',
    mode: PRISTINE,
    trackingStepName: 'address'
  }
  steps[CHECKOUT_BANK_DATA] = {
    title: 'Bankverbindung',
    mode: PRISTINE,
    trackingStepName: 'bankaccount'
  }
  steps[CHECKOUT_TOPUP_DATA] = {
    title: 'Automatische Aufladung',
    mode: INVISIBLE
  }
  steps[CHECKOUT_PASSWORD_DATA] = {
    title: 'Kennwort für Ihr Kundenkonto',
    mode: PRISTINE,
    trackingStepName: 'password'
  }
  steps[CHECKOUT_PERMISSIONS] = {
    title: 'Hinweise & Einwilligung',
    skipValidation: true,
    keepOpen: true,
    mode: PRISTINE,
    trackingStepName: 'agreement'
  }
  steps[CHECKOUT_VZDOC] = {
    title: 'Gesetzliche Pflichtinformationen',
    keepOpen: true,
    mode: PRISTINE,
    trackingStepName: 'download'
  }
  steps[CHECKOUT_MNP] = {
    title: 'Rufnummer mitbringen',
    optional: true,
    optionalButtonText: 'Rufnummer mitbringen',
    mode: PRISTINE,
    trackingStepName: 'phonenumber'
  }
  steps[CHECKOUT_MGM] = {
    title: 'Freunde werben Freunde',
    optional: true,
    optionalButtonText: '5 € Bonus sichern',
    mode: PRISTINE,
    trackingStepName: 'referAFriend'
  }

  return steps
}

export const ACTIVATION_PERSONAL_DATA = 0,
  ACTIVATION_ADDRESS_DATA = 1,
  ACTIVATION_TOPUP_DATA = 2,
  ACTIVATION_BANK_DATA = 3,
  ACTIVATION_PASSWORD_DATA = 4,
  ACTIVATION_PERMISSIONS = 5,
  ACTIVATION_VZDOC = 6,
  ACTIVATION_MGM = 7

export const getDefaultActivationSteps = () => {
  const steps = []

  steps[ACTIVATION_PERSONAL_DATA] = { title: 'Vertragsinhaber', mode: PRISTINE }
  steps[ACTIVATION_ADDRESS_DATA] = { title: 'Lieferadresse', mode: PRISTINE }
  steps[ACTIVATION_TOPUP_DATA] = {
    title: 'Auflademöglichkeiten',
    mode: INVISIBLE
  }
  steps[ACTIVATION_BANK_DATA] = { title: 'Bankverbindung', mode: INVISIBLE }

  steps[ACTIVATION_PASSWORD_DATA] = {
    title: 'Kennwort für Ihr Kundenkonto',
    mode: PRISTINE
  }

  steps[ACTIVATION_PERMISSIONS] = {
    title: 'Hinweise & Einwilligung',
    skipValidation: true,
    keepOpen: true,
    mode: PRISTINE
  }
  steps[ACTIVATION_VZDOC] = {
    title: 'Gesetzliche Pflichtinformationen',
    keepOpen: true,
    mode: PRISTINE
  }
  steps[ACTIVATION_MGM] = {
    title: 'Freunde werben Freunde',
    optional: true,
    optionalButtonText: '5 € Bonus sichern',
    mode: PRISTINE
  }

  return steps
}

export const SIM_CARD_TRANSFER_PERSONAL_DATA = 0,
  SIM_CARD_TRANSFER_ADDRESS_DATA = 1,
  SIM_CARD_TRANSFER_TOPUP_DATA = 2,
  SIM_CARD_TRANSFER_PASSWORD_DATA = 3,
  SIM_CARD_TRANSFER_PERMISSIONS = 4

export const getDefaultSimCardTransferSteps = () => {
  const steps = []

  steps[SIM_CARD_TRANSFER_PERSONAL_DATA] = {
    title: 'Neuer Vertragsinhaber',
    mode: PRISTINE
  }

  steps[SIM_CARD_TRANSFER_ADDRESS_DATA] = {
    title: 'Lieferadresse',
    mode: PRISTINE
  }
  steps[SIM_CARD_TRANSFER_TOPUP_DATA] = {
    title: 'Auflademöglichkeiten',
    mode: PRISTINE
  }
  steps[SIM_CARD_TRANSFER_PASSWORD_DATA] = {
    title: 'Kennwort für Ihr Kundenkonto',
    mode: PRISTINE
  }
  steps[SIM_CARD_TRANSFER_PERMISSIONS] = {
    title: 'Hinweise & Einwilligung',
    skipValidation: true,
    keepOpen: true,
    mode: PRISTINE
  }

  return steps
}

export const setMode = (state, index, newMode) => {
  state.steps = state.steps.map((e, i) => {
    if (index === i) {
      return Object.assign({ ...e }, { mode: newMode })
    } else {
      return { ...e }
    }
  })
}

export const getStepGetters = () => ({
  getSteps: state => {
    return state.steps
  },
  isLastStepReached: state => {
    let lastIndex = 0

    for (let curIndex = 0; curIndex < state.steps.length; curIndex++) {
      const current = state.steps[curIndex]

      if (current.optional !== true && current.mode !== INVISIBLE) {
        lastIndex = curIndex
      }
    }

    return lastIndex === state.lastDirtyStep
  }
})

export const getStepMutations = getDefaultSteps => ({
  setFinal(state, { index, value }) {
    state.steps[index].final = value
  },
  setModeDirty(state, index) {
    setMode(state, index, DIRTY)

    if (state.lastDirtyStep < index && state.steps[index].optional !== true) {
      state.lastDirtyStep = index
    }
  },
  makeVisible(state, index) {
    if (state.steps[index].mode === INVISIBLE) {
      setMode(state, index, PRISTINE)
    }
  },
  setModeInvisible(state, index) {
    setMode(state, index, INVISIBLE)
  },
  setModePristine(state, index) {
    setMode(state, index, PRISTINE)
  },
  setModeOverview(state, index) {
    setMode(state, index, OVERVIEW)
  },
  resetSteps(state) {
    state.steps = getDefaultSteps()
    state.lastDirtyStep = 0
  }
})

const getNextStep = ({ getters, commit, dispatch }, index = -1) => {
  const steps = getters['getSteps']

  for (let next = index + 1; next < steps.length; next++) {
    const nextStep = steps[next]

    if (
      !nextStep.optional &&
      nextStep.mode !== OVERVIEW &&
      nextStep.mode !== INVISIBLE
    ) {
      commit('setModeDirty', next)

      if (nextStep.keepOpen) {
        dispatch('getNextStep', next)
      }

      break
    }
  }
}

export const getCheckoutActions = () => ({
  trackStepAction({ getters, rootGetters }, { index = -1, status }) {
    const steps = getters['getSteps']

    if (
      status &&
      index >= 0 &&
      index < steps.length &&
      steps[index].trackingStepName
    ) {
      const checkoutData = {
        step: steps[index].trackingStepName,
        status
      }

      tracking
        .getGtm()
        .trackCheckoutStep(rootGetters['tariff/getTitle'], checkoutData)
    }
  },
  getNextStep
})

export const getActivationActions = () => ({
  trackStepAction() {
    // no tracking implemented for activation yet
    return
  },
  getNextStep
})

export const getSimCardTransferActions = () => ({
  trackStepAction() {
    // no tracking implemented for sim card transfer yet
    return
  },
  getNextStep
})

export const checkoutSteps = {
  getDefaultState: () => ({
    steps: getDefaultCheckoutSteps(),
    lastDirtyStep: 0
  }),
  getters: getStepGetters(),
  mutations: getStepMutations(getDefaultCheckoutSteps),
  actions: getCheckoutActions()
}

export const activationSteps = {
  getDefaultState: () => ({
    steps: getDefaultActivationSteps(),
    lastDirtyStep: 0
  }),
  getters: getStepGetters(),
  mutations: getStepMutations(getDefaultActivationSteps),
  actions: getActivationActions()
}

export const simCardTransferSteps = {
  getDefaultState: () => ({
    steps: getDefaultSimCardTransferSteps(),
    lastDirtyStep: 0
  }),
  getters: getStepGetters(),
  mutations: getStepMutations(getDefaultSimCardTransferSteps),
  actions: getSimCardTransferActions()
}
