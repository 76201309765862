const selfcarePath = '/selfcare/daten'

export default [
  {
    path: selfcarePath,
    name: 'SelfcareData',
    meta: { title: 'Persönliche Daten', layout: 'selfcare' },
    component: () => import('src/components/selfcare/my_data/home/home.vue')
  },
  {
    path: selfcarePath + '/vertragsinhaber',
    name: 'SelfcareDataCustomerData',
    meta: { title: 'Vertragsinhaber', layout: 'selfcare' },
    component: () =>
      import('src/components/selfcare/my_data/customer_data/customer_data.vue')
  },
  {
    path: selfcarePath + '/adressdaten',
    name: 'SelfcareDataAddressData',
    meta: { title: 'Adressdaten', layout: 'selfcare' },
    component: () =>
      import('src/components/selfcare/my_data/address_data/address_data.vue')
  },
  {
    path: selfcarePath + '/bankdaten',
    name: 'SelfcareDataBankData',
    meta: { title: 'Zahlungsdaten', layout: 'selfcare' },
    component: () =>
      import('src/components/selfcare/my_data/bank_data/bank_data.vue')
  },
  {
    path: selfcarePath + '/kennwort',
    name: 'SelfcareDataChangePassword',
    meta: { title: 'Kennwort ändern', layout: 'selfcare' },
    component: () =>
      import(
        'src/components/selfcare/my_data/change_password/change_password.vue'
      )
  },
  {
    path: selfcarePath + '/pin-puk',
    name: 'SelfcareDataPinPuk',
    meta: { title: 'PIN & PUK', layout: 'selfcare' },
    component: () =>
      import('src/components/selfcare/my_data/pin_puk/pin_puk.vue')
  },
  {
    path: selfcarePath + '/umsatzsteuerbelege',
    name: 'SelfcareDataSalesTax',
    meta: { title: 'Umsatzsteuerbelege', layout: 'selfcare' },
    component: () =>
      import('src/components/selfcare/my_data/sales_tax/sales_tax.vue')
  },
  {
    path: selfcarePath + '/weitere-einstellungen',
    name: 'SelfcareDataSettings',
    meta: { title: 'Weitere Einstellungen', layout: 'selfcare' },
    component: () =>
      import('src/components/selfcare/my_data/settings/settings.vue')
  },
  {
    path: selfcarePath + '/entgeltnachweise',
    name: 'SelfcareDataRemunerationStatement',
    meta: { title: 'Entgeltnachweise', layout: 'selfcare' },
    component: () =>
      import(
        'src/components/selfcare/my_data/renumeration_statement/renumeration_statement.vue'
      )
  },
  {
    path: selfcarePath + '/telefonbucheintrag',
    name: 'SelfcareDataPhonebook',
    meta: { title: 'Telefonbucheintrag', layout: 'selfcare' },
    component: () =>
      import('src/components/selfcare/my_data/phonebook/phonebook.vue')
  },
  {
    path: selfcarePath + '/einwilligungserklaerung',
    name: 'SelfcareDataContactPermissions',
    meta: { title: 'Einwilligungserklärung', layout: 'selfcare' },
    component: () =>
      import(
        'src/components/selfcare/my_data/contact_permissions/contact_permissions.vue'
      )
  },
  {
    path: selfcarePath + '/session-management',
    name: 'SelfcareDataManageSessions',
    meta: { title: '- Session Management', layout: 'selfcare' },
    component: () =>
      import(
        'src/components/selfcare/my_data/manage_sessions/manage_sessions.vue'
      )
  }
]
