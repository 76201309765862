import { downloadPdf } from 'src/lib/helpers'
import { formatDate } from 'src/lib/date'
import { getField, updateField } from 'vuex-map-fields'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  verificationForActivation: false,
  verificationForSimCardTransfer: false,
  videoIdent: {
    available: false,
    nextAvailableTime: undefined,
    key: undefined,
    type: 'VIDEO_IDENT'
  },
  postIdent: {
    available: false,
    nextAvailableTime: undefined,
    key: undefined,
    type: 'POST_IDENT'
  },
  idvReference: undefined,
  idvMethodsReceived: false,
  canModifyRegistration: false,
  canResetRegistration: false,
  renderTrustedShopsOrderInfo: false,
  trustedShopsCheckoutData: {
    orderId: undefined,
    orderEmailAddress: undefined,
    orderAmount: undefined
  },
  error: false,
  errorMessage: undefined,
  login: {
    birthDate: undefined,
    zipCode: undefined,
    reEntryId: undefined,
    msisdn: undefined,
    activationCode: undefined,
    error: false,
    errorMessage: undefined
  },
  changeData: {
    error: false,
    errorMessage: undefined
  },
  resetRegistration: {
    error: false,
    errorMessage: undefined
  }
})

const state = getDefaultState()

const getters = {
  getField,
  getLoginReEntryDto: state => {
    return {
      zipCode: state.login.zipCode,
      reEntryId: state.login.reEntryId,
      birthDate: state.login.birthDate
    }
  },
  getLoginActivationCode: state => {
    return {
      msisdn: state.login.msisdn,
      icc: state.login.activationCode,
      birthDate: state.login.birthDate
    }
  },
  getDto: state => {
    return {
      idvReference: state.idvReference,
      canModifyRegistration: state.canModifyRegistration,
      canResetRegistration: state.canResetRegistration
    }
  },
  notAvailableButCouldBeAvailable: state => {
    return (
      (!state.videoIdent.available &&
        Date.now() >
          new Date(Date.parse(state.videoIdent.nextAvailableTime))) ||
      (!state.postIdent.available &&
        Date.now() > new Date(Date.parse(state.postIdent.nextAvailableTime)))
    )
  },
  getChangedDataDto: (state, getters, rootState, rootGetters) => {
    return {
      address: rootGetters['address/getDto'],
      personalData: rootGetters['personal/getDto'],
      ...rootGetters['bank/getDto']
    }
  }
}

const actions = {
  async loginWithReEntryId({ dispatch, getters }) {
    return dispatch('login', {
      url: '/rest-api/v1/identification/session/order',
      dto: getters['getLoginReEntryDto']
    })
  },
  async loginWithActivationCode({ dispatch, getters }) {
    return dispatch('login', {
      url: '/rest-api/v1/identification/session/activation',
      dto: getters['getLoginActivationCode']
    })
  },
  async login({ commit, dispatch, rootGetters }, { url, dto }) {
    try {
      await axios.post(url, dto)

      return true
    } catch (e) {
      dispatch('apiErrors/handleError', { ...e, silent: true }, { root: true })
      commit('loginError', rootGetters['apiErrors/getErrorMessage'])
    }

    return false
  },
  async getCustomerDataForChange({ commit, dispatch, rootGetters }) {
    try {
      const response = await axios.get(
        '/rest-api/v1/identification/customer-data'
      )

      response.data.personalData.birthdate = formatDate(
        response.data.personalData.birthdate
      )
      commit('personal/setPersonalData', response.data.personalData, {
        root: true
      })
      commit('address/setAddressData', response.data.address, { root: true })
      commit('bank/updateBankData', response.data.bankData, { root: true })
      commit('bank/updateBankDataMandatory', response.data.isBankDataRequired, {
        root: true
      })
      commit('changeDataOk')
    } catch (e) {
      dispatch('apiErrors/handleError', { ...e, silent: true }, { root: true })
      commit('changeDataError', rootGetters['apiErrors/getErrorMessage'])
    }
  },
  async sendChangedCustomerData({ commit, getters, dispatch, rootGetters }) {
    try {
      await axios.put(
        '/rest-api/v1/identification',
        getters['getChangedDataDto']
      )
      commit('changeDataOk')

      return true
    } catch (e) {
      dispatch('apiErrors/handleError', { ...e, silent: true }, { root: true })
      commit('changeDataError', rootGetters['apiErrors/getErrorMessage'])

      return false
    }
  },
  async resetRegistration({ commit, dispatch, rootGetters }) {
    try {
      await axios.delete('/rest-api/v1/identification')

      return true
    } catch (e) {
      dispatch('apiErrors/handleError', { ...e, silent: true }, { root: true })
      commit('resetRegistrationError', rootGetters['apiErrors/getErrorMessage'])

      return false
    }
  },
  async invokeVideoIdent({ state, commit, dispatch }) {
    const response = await dispatch('invokeIdent', state.videoIdent.key)

    if (response && response.startUrl && response.startUrl.length > 5) {
      window.location.href = response.startUrl
    } else {
      commit('updateRequestError', 'Malformed Start-Url')
    }
  },
  invokePostIdent({ state, dispatch }) {
    dispatch('invokeIdent', state.postIdent.key).then(() => {
      downloadPdf(
        '/rest-api/v1/identification/document',
        {},
        'PostIdent Formular'
      )
    })
  },
  async invokeIdent({ commit, dispatch, rootGetters }, key) {
    try {
      const response = await axios.post('/rest-api/v1/identification/invoke', {
        key
      })

      return response.data
    } catch (e) {
      dispatch('apiErrors/handleError', { ...e, silent: true }, { root: true })
      commit('updateRequestError', rootGetters['apiErrors/getErrorMessage'])

      return false
    }
  },
  async verificationRequestDetails({
    commit,
    state,
    getters,
    rootGetters,
    dispatch
  }) {
    if (!state.idvReference || getters['notAvailableButCouldBeAvailable']) {
      try {
        const response = await axios.get('/rest-api/v1/identification/methods')

        commit('updateVerificationRequestDetails', response.data)
      } catch (e) {
        dispatch(
          'apiErrors/handleError',
          { ...e, silent: true },
          { root: true }
        )
        commit('updateRequestError', rootGetters['apiErrors/getErrorMessage'])
      }
    }
  },
  async getIdentificationSession(
    { dispatch, commit, rootGetters },
    { token, brandKey }
  ) {
    try {
      await axios.post('/rest-api/v2/user-data/email/identification-session', {
        brandKey,
        token
      })
    } catch (e) {
      dispatch('apiErrors/handleError', { ...e, silent: true }, { root: true })
      commit('updateRequestError', rootGetters['apiErrors/getErrorMessage'])
    }
  }
}

const mutations = {
  updateField,
  loginError(state, userMessage) {
    state.login.error = true
    state.login.errorMessage = userMessage
  },
  updateRequestError(state, userMessage) {
    state.error = true
    state.errorMessage = userMessage
  },
  resetRegistrationError(state, userMessage) {
    state.resetRegistration.error = true
    state.resetRegistration.errorMessage = userMessage
  },
  changeDataOk(state) {
    state.changeData.error = false
    state.changeData.errorMessage = undefined
  },
  changeDataError(state, userMessage) {
    state.changeData.error = true
    state.changeData.errorMessage = userMessage
  },
  setVerificationForActivation(state, value) {
    state.verificationForActivation = value
  },
  setVerificationForSimCardTransfer(state, value) {
    state.verificationForSimCardTransfer = value
  },
  updateVerificationRequestDetails(state, response) {
    state.error = false
    state.errorMessage = undefined
    state.idvReference = response.idvReference
    state.canModifyRegistration = response.canModifyRegistration
    state.canResetRegistration = response.canResetRegistration
    state.trustedShopsCheckoutData.orderId = response.orderId
    state.trustedShopsCheckoutData.orderAmount = response.orderAmount
    state.trustedShopsCheckoutData.orderEmailAddress =
      response.orderEmailAddress
    state.renderTrustedShopsOrderInfo = response.renderTrustedShopsOrderInfo
    state.idvMethodsReceived = true
    response.methods.forEach(method => {
      if (method.type === state.videoIdent.type) {
        state.videoIdent.available = method.available
        state.videoIdent.key = method.key
        state.videoIdent.nextAvailableTime = new Date(
          Date.parse(method.nextAvailableTime)
        )
      }

      if (method.type === state.postIdent.type) {
        state.postIdent.available = method.available
        state.postIdent.key = method.key
        state.postIdent.nextAvailableTime = new Date(
          Date.parse(method.nextAvailableTime)
        )
      }
    })
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'verification',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
